<template>
  <div>
    <SlideShow :bannerList="bannerList"></SlideShow>
    <div class="searchBar">
      <Search
        type="1"
        :keys="getListParams.key"
        @searchKeyFun="searchKeyFun"
      ></Search>
    </div>
    <template>
      <div class="main_Div contBox">
        <CourseClassifyMenu
          :keys="getListParams.key"
          @changeMenu="changeMenu"
          @selectSort="selectSort"
          @selectFreeFun="selectFreeFun"
          @selectReComFun="selectReComFun"
          @selectVideoTypeFun="selectVideoTypeFun"
          @selectTimeFun="selectTimeFun"
          @searchKeyFun="searchKeyFun"
          @changeData="changeData"
        ></CourseClassifyMenu>
        <div class="ListBox">
          <CourseList
            :courseList="courseListObj.data"
            :loading="loading"
          ></CourseList>
        </div>
        <div class="pageBox">
          <el-pagination
            background
            layout="prev, pager, next"
            :current-page="getListParams.page"
            @current-change="setPage"
            :total="courseListObj.total"
            :page-size="courseListObj.per_page"
            v-if="courseListObj.total"
          ></el-pagination>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import SlideShow from "@/components/SlideShow";
import Search from "@/components/Search";
import CourseClassifyMenu from "@/components/CourseClassifyMenu";
import CourseList from "@/components/CourseList";
import { getBanner } from "../../Api";
import { mapGetters } from "vuex";

export default {
  name: "CourseIndex",
  components: {
    SlideShow,
    Search,
    CourseClassifyMenu,
    CourseList,
  },
  data() {
    return {
      bannerList: [],
      loading: true,
      getListParams: {
        id: 6,
        child: null,
        free: 0,
        page: 1,
        px: 1,
        reCom: 0,
        videoType: 0,
        key: "",
        dataTime: null,
        comeDownBanner: "",
      },
    };
  },
  created() {
    this.initData();
  },
  methods: {
    initData() {
      const params = {
        position: 1035,
        cate: 1050,
      };
      getBanner(params).then((res) => {
        this.bannerList = res.data;
      });
    },
    //修改请求数据
    changeData(data) {
      this.getListParams.page = 1;
      this.getListParams = data;
    },
    //选择分类
    changeMenu(data) {
      this.getListParams.page = 1;
      this.getListParams.child = data;
    },
    //  选择排序
    selectSort(data) {
      this.getListParams.page = 1;
      this.getListParams.px = data;
    },
    //  选择筛选
    selectFreeFun(data) {
      this.getListParams.page = 1;
      this.getListParams.free = data;
    },
    //  选择推荐
    selectReComFun(data) {
      this.getListParams.page = 1;
      this.getListParams.reCom = data;
    },
    // 选择视频类型
    selectVideoTypeFun(data) {
      this.getListParams.page = 1;
      this.getListParams.videoType = data;
    },
    //  选择时间
    selectTimeFun(data) {
      this.getListParams.page = 1;
      this.getListParams.dataTime = data;
    },
    //  选定分页
    setPage(val) {
      this.getListParams.page = val;
      //回到顶部
      cancelAnimationFrame(this.timer);
      const self = this;
      self.timer = requestAnimationFrame(function fn() {
        const oTop =
          document.body.scrollTop || document.documentElement.scrollTop;
        if (oTop > 0) {
          document.body.scrollTop = document.documentElement.scrollTop =
            oTop - 100;
          self.timer = requestAnimationFrame(fn);
        } else {
          cancelAnimationFrame(self.timer);
        }
      });
    },
    //  搜索
    searchKeyFun(keys) {
      this.getListParams.page = 1;
      this.getListParams.key = keys;
    },
  },
  mounted() {},
  computed: {
    ...mapGetters(["courseListObj"]),
  },

  watch: {
    getListParams: {
      handler: function () {
        this.loading = true;
        this.$store
          .dispatch("app/getCourseList", this.getListParams)
          .then(() => {
            this.loading = false;
          });
      },
      deep: true,
    },
  },
};
</script>
<style scoped>
::v-deep .is-animated {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.pageBox {
  width: 100%;
  text-align: center;
}

.searchBar {
  position: absolute;
  width: 100%;
  min-width: 1400px;
  top: 2rem;
  z-index: 8;
}

.contBox {
  position: relative;
  top: -0.66rem;
  z-index: 8;
}
</style>
